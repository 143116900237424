import React from "react";
import styled from "styled-components";

export default function Heading({ activeTab }) {
  return (
    <Container>
        {activeTab == "employee" && <p>Manage Admins</p>}
        {activeTab == "deposits" && <p>Manage Deposit Requests</p>}
        {activeTab == "dashboard" && <p>Dashboard</p>}

    </Container>
  );
}

const Container = styled.div`
  width: 100%;
  height: 80px;
  background-color: white;
  box-sizing: border-box;

  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);

  display: flex;
  align-items: center;
  padding: 2em;

  p {
    margin: 0;
    font-weight: 500;
    font-size: 25px;
  }
`;
