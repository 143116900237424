import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadBytes, getDownloadURL, ref, listAll } from "firebase/storage";
import { collection, doc, getDoc, updateDoc, serverTimestamp } from "@firebase/firestore";
import { firestore as db } from "../apis/firebase";
import { storage } from "../apis/firebase";
import { Milkdown, useEditor } from "@milkdown/react";
import { nord } from "@milkdown/theme-nord";
import { Editor, rootCtx, defaultValueCtx } from "@milkdown/core";
import { commonmark } from "@milkdown/preset-commonmark";
import { MilkdownProvider } from "@milkdown/react";
import "./markdown.css";
import { listener, listenerCtx } from "@milkdown/plugin-listener";
import { emoji } from "@milkdown/plugin-emoji";
import { clipboard } from '@milkdown/plugin-clipboard';
import { gfm } from '@milkdown/preset-gfm';
import 'prosemirror-view/style/prosemirror.css';
import { useSearchParams } from "react-router-dom";

const MilkdownEditor = ({ setMarkdownContent, initialContent }) => {
  const { get } = useEditor((root) =>
    Editor.make()
      .config((ctx) => {
        ctx.get(listenerCtx).markdownUpdated((ctx, m, prevMarkdown) => {
          setMarkdownContent(m);
        });
        ctx.set(rootCtx, root);
        ctx.set(defaultValueCtx, initialContent);
      })
      .use(nord)
      .use(commonmark)
      .use(listener)
      .use(emoji)
      .use(gfm)
      .use(clipboard)
  );

  return <Milkdown />;
};

export default function EditAnnouncement() {
  const [searchParams] = useSearchParams();
  const announcementId = searchParams.get("id");

  const [markdownContent, setMarkdownContent] = useState("");
  const [ordinanceTitle, setOrdinanceTitle] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [initialContent, setInitialContent] = useState(null);

  useEffect(() => {
    const fetchAnnouncement = async () => {
      const docRef = doc(db, "announcements", announcementId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        console.log(data

        )
        setOrdinanceTitle(data.title);
        setMarkdownContent(data.content);
        setInitialContent(data.content);
      } else {
        console.log("No such document!");
      }
    };

    fetchAnnouncement();
  }, [announcementId]);

  const submitOrdinance = async () => {
    const docRef = doc(db, "announcements", announcementId);
    await updateDoc(docRef, {
      content: markdownContent,
      title: ordinanceTitle,
      updatedAt: serverTimestamp(),
    });
    window.location.href = `/edit?id=${announcementId}`;
  };

  const handleFileUpload = async () => {
    const fileInput = document.getElementById("file_input");
    const file = fileInput.files[0];
    const storageRef = ref(storage, `${file.name}`);
    const result = await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(result.ref);
    setUploadedImages((prevImages) => [...prevImages, downloadURL]);
  };

  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = ref(storage);
      const imagesFolderRef = ref(storageRef, "");
      const imageList = await listAll(imagesFolderRef);
      const urls = await Promise.all(
        imageList.items.map((imageRef) => getDownloadURL(imageRef))
      );
      setUploadedImages(urls);
    };
    fetchImages();
  }, []);

  const copyMarkdownToClipboard = (image) => {
    const markdownSyntax = `![alt_text](${image} "title")`;
    navigator.clipboard
      .writeText(markdownSyntax)
      .then(() => {
        showToast("Markdown copied to clipboard!");
      })
      .catch((error) => {
        console.error("Failed to copy Markdown to clipboard: ", error);
        toast.error("Failed to copy Markdown to clipboard!");
      });
  };

  const showToast = (message) => {
    toast.success(message, {
      autoClose: 3000,
    });
  };

  return (
    <div className="markdown-editor">
      <h1>Edit Announcement</h1>
      <input
        type="text"
        id="title"
        placeholder="Title"
        value={ordinanceTitle}
        onChange={(e) => setOrdinanceTitle(e.target.value)}
      />
      <div className="editor-container">
        <MilkdownProvider>
            {
                initialContent && 
          <MilkdownEditor setMarkdownContent={setMarkdownContent} initialContent={initialContent} />
            }
        </MilkdownProvider>
      </div>
      <button className="cms-submit" onClick={submitOrdinance}>
        Submit
      </button>
      <div className="images" id="images_container">
        <div className="list">
          {uploadedImages.map((image, index) => (
            <img
              key={index}
              src={image}
              alt="Uploaded"
              onClick={() => copyMarkdownToClipboard(image)}
            />
          ))}
        </div>
        <div className="upload">
          <input
            type="file"
            id="file_input"
            accept="image/png, image/gif, image/jpeg"
          />
          <button onClick={handleFileUpload}>Upload</button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
