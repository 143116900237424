import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import searchIcon from "../../images/search.png";
import { auth, firestore } from "../../apis/firebase";
import {
  doc,
  onSnapshot,
  collection,
  getDoc,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

export default function WithdrawalRequests() {
  const [withdrawals, setRequests] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(firestore, "withdrawal_requests"),
      async (snapshot) => {
        const requests = [];
        await Promise.all(
          snapshot.docs.map(async (doc) => {
            const request = { id: doc.id, ...doc.data() };
            if (!(request.status == "verified" || request.status == "rejected")) {
              request["email"] = await getEmail(request.requester_uid, request.requester);
              requests.push(request);
            }
          })
        );
        setRequests(requests);
      }
    );

    return () => unsubscribe();
  }, []);

  function capitalizeFirstLetter(str) {
    if (str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  const getEmail = async (id, type) => {
    const collectionName = type == "user" ? "users" : type ==  "agent" ?  "agents" : "admins";
    const docRef = doc(collection(firestore, collectionName), id);
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    console.log(data, collectionName, type, id)
    return data["email"] ?? "email not found";
  };

  const handleVerify = async () => {
    if (!selectedRequest) return;
    const { requester_uid, amount } = selectedRequest;
    const walletsRef = collection(firestore, "wallets");
    const q = query(walletsRef, where("uid", "==", requester_uid));

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Update the first wallet found for the user
        const walletDoc = querySnapshot.docs[0];
        const walletRef = walletDoc.ref;
        // await updateDoc(walletRef, {
        //   balance: walletDoc.data().balance - amount,
        //   pending_withdrawal: walletDoc.data().pending_withdrawal - amount,
        // });
      } else {
        // Handle case where no wallet exists
        console.error("No wallet found for the user");
      }

      // Optionally, you can remove the withdrawal request or update its status
      await updateDoc(doc(firestore, "withdrawal_requests", selectedRequest.id), {
        status: "verified",
      });

      const colRef = collection(firestore, "transactions");
      await addDoc(colRef, {
        uid: requester_uid,
        message: 'Your withdrawal request has been verified',
        amount: 0,
        timestamp: serverTimestamp(),
      });

      setShowModal(false);
    } catch (error) {
      console.error("Error verifying withdrawal: ", error);
    }
  };

  const handleReject = async () => {
    if (!selectedRequest || !rejectionReason) return;

    const { requester_uid, amount } = selectedRequest;
    const walletsRef = collection(firestore, "wallets");
    const q = query(walletsRef, where("uid", "==", requester_uid));

    try {
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Update the first wallet found for the user
        const walletDoc = querySnapshot.docs[0];
        const walletRef = walletDoc.ref;
        await updateDoc(walletRef, {
          balance: walletDoc.data().balance + amount,
        });
      }

      // Update withdrawal request status and rejection reason
      await updateDoc(doc(firestore, "withdrawal_requests", selectedRequest.id), {
        status: "rejected",
        rejection_reason: rejectionReason,
      });

      const colRef = collection(firestore, "transactions");
      await addDoc(colRef, {
        uid: requester_uid,
        message: `Withdraw rejected: (${rejectionReason})`,
        amount: amount,
        timestamp: serverTimestamp(),
      });

      setShowRejectModal(false);
      setShowModal(false);
    } catch (error) {
      console.error("Error rejecting withdrawal: ", error);
    }
  };

  return (
    <Container>
      <Actions></Actions>
      <table>
        <tr>
          <th>Requester</th>
          <th>Email</th>
          <th>Gcash Number</th>
          <th>Amount</th>
          <th>Action</th>
        </tr>
        <tbody>
          {withdrawals.map((request) => (
            <tr key={request.id}>
              <td>{capitalizeFirstLetter(request.requester)}</td>
              <td>{request.email}</td>
              <td>{request.bank_account_number}</td>
              <td>{request.amount}</td>
              <td className="action">
                <button
                  onClick={() => {
                    setShowModal(true);
                    setSelectedRequest(request);
                  }}
                >
                  Verify
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showModal && (
        <VerifyModal
          onClick={() => {
            setShowModal(false);
          }}
        >
          <div
            className="container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {selectedRequest && (
              <>
                <h2>Verify Transaction</h2>
                <p>
                  <b>Amount:</b> <u>{selectedRequest.amount}</u>
                </p>
                <button onClick={handleVerify}>Verify</button>
                <button
                  className="reject"
                  onClick={() => {
                    setShowRejectModal(true);
                  }}
                >
                  Reject
                </button>
              </>
            )}
          </div>
        </VerifyModal>
      )}
      {showRejectModal && (
        <RejectModal
          onClick={() => {
            setShowRejectModal(false);
          }}
        >
          <div
            className="container"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2>Reject Transaction</h2>
            <textarea
              placeholder="Enter rejection reason"
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
            <button onClick={handleReject}>Confirm Rejection</button>
          </div>
        </RejectModal>
      )}
    </Container>
  );
}

const RejectModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000001c;
  .container {
    width: 400px;
    background-color: #fcfcfc;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    gap: 1em;
    p,
    h2 {
      margin: 0;
    }

    textarea {
      width: 100%;
      height: 100px;
      border-radius: 10px;
      padding: 10px;
      border: 1px solid #ccc;
    }
  }
  button {
    border: none;
    border-radius: 10px;
    padding: 1em 2em;
    cursor: pointer;
    width: 70%;
    background-color: #d9534f;
    color: white;
  }
`;

const VerifyModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000001c;
  .container {
    min-width: 400px;
    background-color: #fcfcfc;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2em;
    gap: 1em;
    p,
    h2 {
      margin: 0;
    }
  }
  .actions {
    display: flex;
    gap: 1em;
  }
  button {
    border: none;
    border-radius: 10px;
    padding: 1em 2em;
    cursor: pointer;
    width: 70%;
    background-color: #4fd961;
  }
  button:nth-child(1) {
    background-color: #aaa;
    color: white;
  }
  button:nth-child(2) {
    background-color: #d9534f;
    color: white;
  }

  .reject {
    background-color: #d9534f;
  }
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2em;
  gap: 3em;
  table {
    width: 90%;
    border-collapse: collapse;
    border: 1px solid #0000001d;

    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);

    th {
      /* border: 1px solid black; */
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
      text-align: left;
      background-color: #04aa6d;
      color: white;
    }
    td {
      /* border: 1px solid black;    */
      height: 2em;
      padding-left: 10px;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    .action {
      display: flex;
      gap: 10px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  justify-content: flex-end;
  padding-right: 2em;

  .search {
    background-color: white;
    padding: 0.75em;
    display: flex;
    gap: 5px;
    border-radius: 10px;
    border: 1px solid #00000020;

    input {
      border: none;
    }

    input:focus {
      outline: none;
    }

    img {
      height: 24px;
    }
  }

  button {
    border: none;
    border-radius: 15px;
    background-color: #1f6ad4;
    color: white;
    padding: 0em 1em;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
  }
`;
