import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import searchIcon from "../../images/search.png";
import { auth, firestore } from "../../apis/firebase";
import {
  doc,
  onSnapshot,
  collection,
  getDoc,
  query,
  where,
  getDocs,
  updateDoc,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

export default function SettingsContent() {
  const [liveAPI, setLiveAPI] = useState({ appID: '', appSign: '' });
  const [gcash, setGcash] = useState({ number: '' });
  const [commission, setCommission] = useState({agent: 0, admin: 0, super_admin: 0})

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(collection(firestore, "settings"),"live_api"),
      async (snapshot) => {
        const data = snapshot.data();
        setLiveAPI(data);
      }
    );

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(collection(firestore, "settings"),"gcash"),
      async (snapshot) => {
        const data = snapshot.data();
        setGcash(data);
      }
    );

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(collection(firestore, "settings"), "commission"),
      async (snapshot) => {
        const data = snapshot.data();
        setCommission(data);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleSave = async (field, value) => {
    if (window.confirm('Do you really want to save the changes?')) {
      try {
        const docRef = doc(collection(firestore, "settings"), field);
        await updateDoc(docRef, value);
        alert('Settings saved successfully');
      } catch (error) {
        console.error("Error updating document: ", error);
        alert('Failed to save settings');
      }
    }
  };

  return (
    <Container>
      <Actions></Actions>
      <table>
        <tr>
          <th>Setting</th>
          <th>Value</th>
          <th>Action</th>
        </tr>
        <tbody>
          <tr>
            <td>Livestream AppID</td>
            <td>
              <input
                type="text"
                value={liveAPI.appID}
                onChange={(e) => setLiveAPI({ ...liveAPI, appID: e.target.value })}
              />
            </td>
            <td>
              <button onClick={() => handleSave("live_api", { appID: liveAPI.appID })}>Save</button>
            </td>
          </tr>
          <tr>
            <td>Livestream AppSign</td>
            <td>
              <input
                type="text"
                value={liveAPI.appSign}
                onChange={(e) => setLiveAPI({ ...liveAPI, appSign: e.target.value })}
              />
            </td>
            <td>
              <button onClick={() => handleSave("live_api", { appSign: liveAPI.appSign })}>Save</button>
            </td>
          </tr>
          <tr>
            <td>Gcash Number</td>
            <td>
              <input
                type="text"
                value={gcash.number}
                onChange={(e) => setGcash({ ...gcash, number: e.target.value })}
              />
            </td>
            <td>
              <button onClick={() => handleSave("gcash", { number: gcash.number })}>Save</button>
            </td>
          </tr>
          <tr>
            <td>Commission Agent</td>
            <td>
              <input
                type="number"
                value={commission.agent}
                onChange={(e) => setCommission({ ...commission, agent: e.target.value })}
              />
            </td>
            <td>
              <button onClick={() => handleSave("commission", { agent: commission.agent })}>Save</button>
            </td>
          </tr>
          <tr>
            <td>Commission Admin</td>
            <td>
              <input
                type="number"
                value={commission.admin}
                onChange={(e) => setCommission({ ...commission, admin: e.target.value })}
              />
            </td>
            <td>
              <button onClick={() => handleSave("commission", { admin: commission.admin })}>Save</button>
            </td>
          </tr>
          <tr>
            <td>Commission Super Admin</td>
            <td>
              <input
                type="number"
                value={commission.super_admin}
                onChange={(e) => setCommission({ ...commission, super_admin: e.target.value })}
              />
            </td>
            <td>
              <button onClick={() => handleSave("commission", { super_admin: commission.super_admin })}>Save</button>
            </td>
          </tr>
        </tbody>
      </table>
    </Container>
  );
}


const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2em;
  gap: 3em;
  table {
    width: 90%;
    border-collapse: collapse;
    border: 1px solid #0000001d;

    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);

    th {
      /* border: 1px solid black; */
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
      text-align: left;
      background-color: #04aa6d;
      color: white;
    }
    td {
      /* border: 1px solid black;    */
      height: 2em;
      padding-left: 10px;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    .action {
      display: flex;
      gap: 10px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  justify-content: flex-end;
  padding-right: 2em;

  .search {
    background-color: white;
    padding: 0.75em;
    display: flex;
    gap: 5px;
    border-radius: 10px;
    border: 1px solid #00000020;

    input {
      border: none;
    }

    input:focus {
      outline: none;
    }

    img {
      height: 24px;
    }
  }

  button {
    border: none;
    border-radius: 15px;
    background-color: #1f6ad4;
    color: white;
    padding: 0em 1em;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
  }
`;
