import React from "react";
import styled from "styled-components";
import { useState } from "react";
import { signInWithEmailAndPassword } from "@firebase/auth";
import { auth } from "../apis/firebase";
import { signOut } from "firebase/auth";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  // Function to handle changes in the email input
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  // Function to handle changes in the password input
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      if(email == "superadmin@aylive.xyz"){
        window.location = "/"
      }else{
        await signOut(auth);
        alert("Your dont have enough privilege to access this website")
        window.location.reload();
      }
    } catch (error) {
      let errorMessage;
      console.log(error.code)
      switch (error.code) {
        case "auth/invalid-email":
          errorMessage = "Invalid email address.";
          break;
        case "auth/user-disabled":
          errorMessage = "User account is disabled.";
          break;
        case "auth/user-not-found":
          errorMessage = "No user found with this email.";
          break;
        case "auth/invalid-credential":
          errorMessage = "Incorrect email or password.";
          break;
        default:
          errorMessage = "Login failed. Please try again.";
          break;
      }
      setError(errorMessage);
      console.log(error);
    }
  };

  return (
    <Container>
      <Modal>
        <h2>Login as Super Admin</h2>
        {error && <Error>{`${error}`}</Error>}
        <Field>
          <label htmlFor="email">Email</label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={handleEmailChange}
          />
        </Field>
        <Field>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
          />
        </Field>
        <Form>
          <button onClick={handleLogin}>Login</button>
        </Form>
      </Modal>
    </Container>
  );
}

const Error = styled.div`
  padding: 1em;
  background-color: #ff0000b5;
  border-radius: 10px;
`;

const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  z-index: 0;
  position: relative;
`;

const Modal = styled.div`
  width: 400px;
  background-color: #f5f5f5d5;
  border-radius: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 1em;
  padding: 1em;
  padding-bottom: 3em;
  transform: scale(1.2);
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  position: relative;
  z-index: 100;
`;

const Field = styled.div`
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 600;
  & > input {
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 0.5em;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    box-sizing: border-box;
  }
`;

const Form = styled.div`
width: 60%;
  & > button {
    width: 100%;
    padding: 0.75em;
    border-radius: 10px;
    box-sizing: border-box;
    border: none;
    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);

    background: #5433ff; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #a5fecb,
      #20bdff,
      #5433ff
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #a5fecb,
      #20bdff,
      #5433ff
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

  & > button:hover {
    background-color: #d8d8d8;
    cursor: pointer;
  }
  & > button:active {
    background-color: #c9c9c9;
    cursor: pointer;
  }
`;