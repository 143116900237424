import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import CreateAnnouncement from './pages/create_announcement';
import EditAnnouncement from './pages/edit_announcement';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}/>
        <Route path="/" element={<Dashboard />}/>
        <Route path="/create" element={<CreateAnnouncement />}/>
        <Route path="/edit" element={<EditAnnouncement />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
