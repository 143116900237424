import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import searchIcon from "../../images/search.png";
import { auth, firestore } from "../../apis/firebase";
import {
  doc,
  setDoc,
  onSnapshot,
  collection,
  query,
  where,
  orderBy,
  limit,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "@firebase/auth";

export default function DashboardContent({ uid }) {
  const [transactions, setTransactions] = useState([]);
  const [wallet, setWallet] = useState(null);
  const [livestreams, setLivestreams] = useState([]);
  const [bettings, setBettings] = useState([]);
  const [users, setUsers] = useState([]);
  const [agents, setAgents] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    const q = query(
      collection(firestore, "deposit_requests"),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setDeposits(employeeData);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const q = query(
      collection(firestore, "withdrawal_requests"),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setWithdrawals(employeeData);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const q = query(
      collection(firestore, "users"),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setUsers(employeeData);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const q = query(
      collection(firestore, "agents"),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setAgents(employeeData);
    });

    return () => unsubscribe();
  }, []);
  useEffect(() => {
    const q = query(
      collection(firestore, "admins"),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setAdmins(employeeData);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const q = query(
      collection(firestore, "transactions"),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setTransactions(employeeData);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const q = query(
      collection(firestore, "bettings"),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setBettings(employeeData);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const q = query(
      collection(firestore, "livestream_schedules"),
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const employeeData = [];
      snapshot.forEach((doc) => {
        employeeData.push({ id: doc.id, ...doc.data() });
      });
      setLivestreams(employeeData);
    });

    return () => unsubscribe();
  }, []);

  function formatFirestoreTimestamp(firestoreTimestamp) {
    // Convert Firestore Timestamp to JavaScript Date object
    const date = firestoreTimestamp.toDate();

    // Format the date
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    return new Intl.DateTimeFormat("en-US", options).format(date);
  }

  useEffect(() => {
    const q = query(
      collection(firestore, "wallets"),
      where("uid", "==", uid), // Adjust the field and condition as needed
      limit(1)
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.forEach((doc) => {
        setWallet({ id: doc.id, ...doc.data() });
      });
    });

    return () => unsubscribe();
  }, []);

  return (
    <Container>
      {
        wallet &&
      <Card>
        <h2>Wallet</h2>
        <div>
        <p>
          <b>Php</b> {wallet.balance.toFixed(2)}
        </p>
        </div>
        
      </Card>
      }
      {
        transactions &&
      <Card>
        <h2>Transactions</h2>
        <div>
        <p>
          {transactions.length}
        </p>
        </div>
        
      </Card>
      }
      {
        livestreams &&
      <Card>
        <h2>Livestreams</h2>
        <div>
        <p>
          {livestreams.length}
        </p>
        </div>
        
      </Card>
      }
      {
        bettings &&
      <Card>
        <h2>Bettings</h2>
        <div>
        <p>
          {bettings.length}
        </p>
        </div>
        
      </Card>
      }
      {
        users &&
      <Card>
        <h2>Users</h2>
        <div>
        <p>
          {users.length}
        </p>
        </div>
        
      </Card>
      }
      {
        agents &&
      <Card>
        <h2>Agents</h2>
        <div>
        <p>
          {agents.length}
        </p>
        </div>
        
      </Card>
      }
      {
        admins &&
      <Card>
        <h2>Admins</h2>
        <div>
        <p>
          {admins.length}
        </p>
        </div>
        
      </Card>
      }
      {
        deposits &&
      <Card>
        <h2>Deposit Requests</h2>
        <div>
        <p>
          {deposits.length}
        </p>
        </div>
        
      </Card>
      }
      {
        withdrawals &&
      <Card>
        <h2>Withdrawal Requests</h2>
        <div>
        <p>
          {withdrawals.length}
        </p>
        </div>
        
      </Card>
      }
    </Container>
  );
}

const Card = styled.div`
  height: 150px;
  border-radius: 20px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  padding: 1em 3em;
  width: 200px;
  max-height: 100px;
  h2{
    margin: 0;
    font-size: 20px;
  }
  background-color: #fefefe;
  p{
    font-size: 22px;
    text-align: right;
  }
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 2em;
  gap: 3em;
  flex-wrap: wrap;
`;


