import React, { act } from "react";
import { useState, useEffect } from "react";
import { onAuthStateChanged, signOut } from "@firebase/auth";
import { auth } from "../apis/firebase";

import Navigation from "./navigation";
import styled from "styled-components";
import Heading from "./heading";
import EmployeeContent from "./contents/admins_content";
import DepositRequests from "./contents/desposit_requests_content";
import UserContent from "./contents/users_content";
import AgentContent from "./contents/agents_content";
import WithdrawalRequests from "./contents/withdraw_content";
import WalletContent from "./contents/wallet_content";
import AllTransactionsContent from "./contents/transactions_all_content";
import SettingsContent from "./contents/settings_content";
import DashboardContent from "./contents/dashboard_content";
import AnalyticsContent from "./contents/analytics_content";
import AnnouncementsContent from "./contents/announcements_content";

export default function Dashboard() {
  const [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("employee");

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in.
        setUser(user);
      } else {
        // No user is signed in.
        setUser(null);
        window.location = "/login";
      }
    });

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Container>
      <Navigation activeTab={activeTab} onChangeTab={setActiveTab} />
      <Content>
        <Heading activeTab={activeTab} />
        <div id="content">
        {user && (
            <>
              {activeTab == "dashboard" && (
                <DashboardContent uid={user.uid} />
              )}
            </>
          )}
          {user && (
            <>
              {activeTab == "analytics" && (
                <AnalyticsContent uid={user.uid} />
              )}
            </>
          )}
          {activeTab == "announcements" && <AnnouncementsContent />}
          {activeTab == "employee" && <EmployeeContent />}
          {activeTab == "users" && <UserContent />}
          {activeTab == "agents" && <AgentContent />}
          {activeTab == "deposits" && <DepositRequests />}
          {activeTab == "withdraws" && <WithdrawalRequests />}
          {user && (
            <>
              {activeTab == "wallet" && (
                <WalletContent uid={user.uid} />
              )}
            </>
          )}
          {activeTab == "transactions" && <AllTransactionsContent />}
          {activeTab == "settings" && <SettingsContent />}
          {activeTab == "leave" && <p>Leave Requests</p>}
          {activeTab == "salary" && <p>Manage Salaries</p>}
          {activeTab == "department" && <p>Manage Departments</p>}
          {activeTab == "training" && <p>Manage Trainings</p>}
          {activeTab == "report" && <p>View Reports</p>}
          {activeTab == "designation" && <p>Assign Designations</p>}
        </div>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  box-sizing: border-box;

 
`;

const Content = styled.div`
  flex-grow: 1;
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  #content {
    width: 100%;
    flex-grow: 1;
  }
`;
