import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { firestore } from "../../apis/firebase";
import { Timestamp, collection, onSnapshot, query } from "firebase/firestore";
import { format } from "date-fns";
import { CChart, CChartLine } from "@coreui/react-chartjs";
import { CAlert } from "@coreui/react";

export default function AnalyticsContent() {
  const [transactions, setTransactions] = useState([]);
  const [attendancesTrain, setAttendancesTrain] = useState([]);
  const [leaves, setLeaves] = useState([]);
  const [viewType, setViewType] = useState("yearly");
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    const q = query(collection(firestore, "transactions"));
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const attendanceData = [];
      await Promise.all(
        snapshot.docs.map(async (doc) => {
          const attendance = { id: doc.id, ...doc.data() };
          attendanceData.push(attendance);
        })
      );
      setTransactions(attendanceData);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const q = query(collection(firestore, "training-attendances"));
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const attendanceData = [];
      await Promise.all(
        snapshot.docs.map(async (doc) => {
          const attendance = { id: doc.id, ...doc.data() };
          attendanceData.push(attendance);
        })
      );
      setAttendancesTrain(attendanceData);
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const q = query(collection(firestore, "leaves"));
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const leavesData = [];
      await Promise.all(
        snapshot.docs.map(async (doc) => {
          const leave = { id: doc.id, ...doc.data() };
          leavesData.push(leave);
        })
      );
      setLeaves(leavesData);
    });
    return () => unsubscribe();
  }, []);

  const handleViewTypeChange = (event) => {
    setViewType(event.target.value);
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const filteredAttendances = transactions.filter((attendance) => {
    console.log(attendance.timestamp)
    if(attendance.timestamp == undefined){
      return;
    }
    const attendanceDate = attendance.timestamp.toDate();
    console.log(attendanceDate)
    if (viewType === "yearly") {
      return attendanceDate.getFullYear() === parseInt(selectedYear);
    } else {
      return (
        attendanceDate.getFullYear() === parseInt(selectedYear) &&
        attendanceDate.getMonth() + 1 === parseInt(selectedMonth)
      );
    }
  });

  
  const filteredAttendancesTrain = attendancesTrain.filter((attendance) => {
    const attendanceDate = new Date(attendance.date);
    if (viewType === "yearly") {
      return attendanceDate.getFullYear() === parseInt(selectedYear);
    } else {
      return (
        attendanceDate.getFullYear() === parseInt(selectedYear) &&
        attendanceDate.getMonth() + 1 === parseInt(selectedMonth)
      );
    }
  });

  const filteredLeaves = leaves.filter((leave) => {
    const leaveDate = new Date(leave.startDate);
    if (viewType === "yearly") {
      return (
        leaveDate.getFullYear() === parseInt(selectedYear) &&
        leave.status.toLowerCase() === "accepted"
      );
    } else {
      return (
        leaveDate.getFullYear() === parseInt(selectedYear) &&
        leaveDate.getMonth() + 1 === parseInt(selectedMonth) &&
        leave.status.toLowerCase() === "accepted"
      );
    }
  });

  const monthlyAttendanceData = Array(12).fill(0);
  const monthlyLeaveData = Array(12).fill(0);
  const monthlyOnTrainData = Array(12).fill(0);
  const dailyAttendanceData = Array(31).fill(0);
  const dailyLeaveData = Array(31).fill(0);
  const dailyOnTrainData = Array(31).fill(0);

  filteredAttendances.forEach((attendance) => {
    if(attendance.timestamp == undefined){
      return;
    }
    const date = attendance.timestamp.toDate();
    if (viewType === "yearly") {
      monthlyAttendanceData[date.getMonth()] += Math.abs(attendance.amount);
    } else {
      dailyAttendanceData[date.getDate() - 1] += Math.abs(attendance.amount);
    }
  });

  console.log(monthlyAttendanceData, dailyAttendanceData)

  filteredLeaves.forEach((leave) => {
    const date = new Date(leave.startDate);
    if (viewType === "yearly") {
      monthlyLeaveData[date.getMonth()] += parseInt(leave.days);
    } else {
      try {
        for (let i = 0; i < leave.days; i++) {
          dailyLeaveData[date.getDate() - 1 + i] += 1;
        }
      } catch (e) {
        console.error(e);
      }
    }
    console.log(date, leave.days, viewType, monthlyLeaveData);
  });

  filteredAttendancesTrain.forEach((attendance) => {
    const date = new Date(attendance.date);
    if (viewType === "yearly") {
      monthlyOnTrainData[date.getMonth()] += 1;
    } else {
      dailyOnTrainData[date.getDate() - 1] += 1;
    }
  });

  const attendanceData = {
    labels:
      viewType === "yearly"
        ? [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ]
        : Array.from(
            { length: new Date(selectedYear, selectedMonth, 0).getDate() },
            (_, i) => i + 1
          ),
    datasets: [
      {
        label: "Transactions (Php)",
        backgroundColor: "rgba(151, 187, 205, 0.2)",
        borderColor: "rgba(151, 187, 205, 1)",
        pointBackgroundColor: "rgba(151, 187, 205, 1)",
        pointBorderColor: "#fff",
        data:
          viewType === "yearly" ? monthlyAttendanceData : dailyAttendanceData,
      },
    ],
  };


  return (
    <Container>
      <SelectWrapper>
        <select value={viewType} onChange={handleViewTypeChange}>
          <option value="yearly">Yearly</option>
          <option value="monthly">Monthly</option>
        </select>
        <select value={selectedYear} onChange={handleYearChange}>
          {Array.from(new Array(10), (v, i) => (
            <option key={i} value={new Date().getFullYear() - i}>
              {new Date().getFullYear() - i}
            </option>
          ))}
        </select>
        {viewType === "monthly" && (
          <select value={selectedMonth} onChange={handleMonthChange}>
            {Array.from({ length: 12 }, (_, i) => (
              <option key={i} value={i + 1}>
                {format(new Date(0, i), "MMMM")}
              </option>
            ))}
          </select>
        )}
      </SelectWrapper>
      <div className="container">
        <Card>
          <CChart
            width={700}
            height={300}
            type="line"
            data={attendanceData}
            customTooltips={false}
          />
        </Card>
      </div>
    </Container>
  );
}

const SelectWrapper = styled.div`
  display: flex;
  gap: 1em;
  margin-bottom: 1em;
`;

const Card = styled.div`
  padding: 1em;
  border-radius: 20px;
  box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
  position: relative;
  width: 800px;
  height: 400px;
`;

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  & > .container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    /* overflow: scroll; */
    height: 100%;
    gap: 1em;
  }
`;
