import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import searchIcon from "../../images/search.png";
import { auth, firestore } from "../../apis/firebase";
import { doc, setDoc, onSnapshot, collection } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "@firebase/auth";

export default function AgentContent() {
  const [agents, setAgents] = useState([]);
  const [search, setSearch] = useState("");
  const [filteredAgents, setFilteredAgents] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(firestore, "agents"), (snapshot) => {
        const agentData = [];
        snapshot.forEach((doc) => {
          const agent = doc.data();
          if (!agent.deleted) {  
            agentData.push({ id: doc.id, ...agent });
          }
        });
        setAgents(agentData);
        setFilteredAgents(agentData);
      })

    return () => unsubscribe();
  }, []);

  // Function to handle form submission
  
  const handleAgentDelete = async (agentId) => {
    if (window.confirm("Are you sure you want to delete this agent?")) {
      try {
        // Set the 'deleted' field of the user to true
        await setDoc(doc(firestore, "agents", agentId), { deleted: true}, { merge: true });
        alert("Agent deleted successfully.");
      } catch (error) {
        console.error("Error deleting agent: ", error);
        alert("Failed to delete agent.");
      }
    }
  }

  const handleSearch = (keyword) => {
    setSearch(keyword);
    if (!keyword.trim()) {
      setFilteredAgents(agents);
    } else {
      const filteredAdmins = agents.filter(agent =>
        [agent.firstName, agent.lastName, agent.email].some(value =>
          value.toLowerCase().includes(keyword.toLowerCase())
        )
      );
      setFilteredAgents(filteredAdmins);
    }
  }
  

  return (
    <Container>
      <Actions>
        <div className="search">
          <img src={searchIcon} alt=""  />
          <input type="text" placeholder="Search for people" onChange={(e) => handleSearch(e.target.value)}/>
        </div>
      </Actions>
      <table>
        <tr>
          <th>Email</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Action</th>
        </tr>
        <tbody>
          {filteredAgents.map((agent) => (
            <tr key={agent.id}>
              <td>{agent.email}</td>
              <td>{agent.firstName}</td>
              <td>{agent.lastName}</td>
              <td className="action">
                <button onClick={() => handleAgentDelete(agent.id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
}
const Error = styled.div`
  padding: 1em;
  background-color: #ff000045;
`;

const RegisterModal = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #0000001c;
  form {
    width: 500px;
    background-color: #fcfcfc;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1em;
    padding-bottom: 2em;

    div {
      width: 250px;
      box-sizing: border-box;
    }

    input {
      width: 100%;
      height: 40px;
      padding: 0em 2em;
      border-radius: 10px;
      box-sizing: border-box;
      border: none;
    }

    #register_submit {
      background-color: #1f6ad4;
      color: white;
      cursor: pointer;
    }
  }
`;

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 2em;
  gap: 3em;
  table {
    width: 90%;
    border-collapse: collapse;
    border: 1px solid #0000001d;

    box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -webkit-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);
    -moz-box-shadow: 0px 5px 8px -3px rgba(0, 0, 0, 0.46);

    th {
      /* border: 1px solid black; */
      padding-top: 12px;
      padding-bottom: 12px;
      padding-left: 10px;
      text-align: left;
      background-color: #04aa6d;
      color: white;
    }
    td {
      /* border: 1px solid black;    */
      height: 2em;
      padding-left: 10px;
    }
    tr:nth-child(even) {
      background-color: #f2f2f2;
    }
    .action {
      display: flex;
      gap: 10px;
    }
  }
`;

const Actions = styled.div`
  display: flex;
  gap: 1em;
  width: 100%;
  justify-content: flex-end;
  padding-right: 2em;

  .search {
    background-color: white;
    padding: 0.75em;
    display: flex;
    gap: 5px;
    border-radius: 10px;
    border: 1px solid #00000020;

    input {
      border: none;
    }

    input:focus {
      outline: none;
    }

    img {
      height: 24px;
    }
  }

  button {
    border: none;
    border-radius: 15px;
    background-color: #1f6ad4;
    color: white;
    padding: 0em 1em;
    font-size: 16px;
    font-weight: 100;
    letter-spacing: 1px;
  }
`;
